.servicesHoursWrap {
  display: flex;
  background-color: lightgray;
  padding: 0 18% 1% 18%;
}

.servicesWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.servicesWrap li {
  font-size: 1.2em;
}

.hoursWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.hoursWrap h3 {
  margin-bottom: 0;
}

.hoursWrap p {
  margin-top: 0;
}
