.footerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(73, 73, 73);
  color: white;
}

.footerWrap h3 {
  margin-bottom: 0;
}

.footerWrap p {
  margin-top: 0;
}
