.apptMapWrap {
  display: flex;
  justify-content: center;
  background-color: rgb(73, 73, 73);
  padding: 0 18% 0 18%;
}

.apptWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.apptWrap h2 {
  color: white;
}

.mapWrap {
  width: 40%;
  height: 19em;
  padding: 3em;
}
