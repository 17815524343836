.adWrap {
  margin: 0 auto;
  text-align: center;
  background-color: red;
  color: white;
}

.adText {
  margin: 0;
  padding: 0.5em;
}
