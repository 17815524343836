@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&family=Rock+Salt&display=swap");

html,
body {
  margin: 0 auto;
  font-family: Raleway;
  height: 100vh;
}

h2 {
  font-weight: bold;
}

#root {
  height: 100vh;
}

@media all and (max-width: 1400px) {
  .apptMapWrap {
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
  .mapWrap {
    width: 80% !important;
  }
}

@media all and (max-width: 800px) {
  .mapWrap {
    padding: 3em 0 3em 0 !important;
  }
  .servicesHoursWrap {
    flex-direction: column;
    align-items: center;
  }

  .servicesWrap {
    width: 100% !important;
  }
}
