@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;700&family=Rock+Salt&display=swap);
html,
body {
  margin: 0 auto;
  font-family: Raleway;
  height: 100vh;
}

h2 {
  font-weight: bold;
}

#root {
  height: 100vh;
}

@media all and (max-width: 1400px) {
  .apptMapWrap {
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
  .mapWrap {
    width: 80% !important;
  }
}

@media all and (max-width: 800px) {
  .mapWrap {
    padding: 3em 0 3em 0 !important;
  }
  .servicesHoursWrap {
    flex-direction: column;
    align-items: center;
  }

  .servicesWrap {
    width: 100% !important;
  }
}

.apptMapWrap {
  display: flex;
  justify-content: center;
  background-color: rgb(73, 73, 73);
  padding: 0 18% 0 18%;
}

.apptWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.apptWrap h2 {
  color: white;
}

.mapWrap {
  width: 40%;
  height: 19em;
  padding: 3em;
}

.footerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(73, 73, 73);
  color: white;
}

.footerWrap h3 {
  margin-bottom: 0;
}

.footerWrap p {
  margin-top: 0;
}

.headerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: red;
}

.headerWrap h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Rock Salt", cursive;
  font-size: 3em;
}

.headerWrap p {
  margin-top: 0;
}

.lineBreak {
  border: 0;
  width: 40%;
  height: 0.1em;
  background-image: linear-gradient(to right, #f0f0f0, #000000, #f0f0f0);
}

.servicesHoursWrap {
  display: flex;
  background-color: lightgray;
  padding: 0 18% 1% 18%;
}

.servicesWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.servicesWrap li {
  font-size: 1.2em;
}

.hoursWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

.hoursWrap h3 {
  margin-bottom: 0;
}

.hoursWrap p {
  margin-top: 0;
}

.adWrap {
  margin: 0 auto;
  text-align: center;
  background-color: red;
  color: white;
}

.adText {
  margin: 0;
  padding: 0.5em;
}


