.headerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: red;
}

.headerWrap h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Rock Salt", cursive;
  font-size: 3em;
}

.headerWrap p {
  margin-top: 0;
}

.lineBreak {
  border: 0;
  width: 40%;
  height: 0.1em;
  background-image: linear-gradient(to right, #f0f0f0, #000000, #f0f0f0);
}
